<div class="auth-modal">

  <button type="button" class="auth-modal__close-btn"></button>

  <h2 class="auth-modal__form--title">Zarejestruj się</h2>

  <form class="auth-modal__form auth-form-sign-up" name="userSignUp">

    <input class="auth-modal__input" type="text" placeholder="Nazwa" name="firstName" minlength="2" required />

    <input class="auth-modal__input" type="text" placeholder="Nazwisko" name="secondName" minlength="2" required />

    <input class="auth-modal__input" type="email" placeholder="E-mail" name="email" minlength="5" required />

    <input class="auth-modal__input" type="tel" placeholder="Numer telefonu: +3809********" name="phone"
      pattern="[\+]\d{12}" required />

    <input class="auth-modal__input" type="password" placeholder="Hasło" name="password" minlength="6" required />

    <input class="auth-modal__input-repeat" type="password" placeholder="Potwierdź hasło" name="passwordRepeat"
      minlength="6" required />

    <button type="submit" class="auth-modal__submit-btn">Zarejestruj się</button>
  </form>
</div>