<div class="adv-modal">
  <button type="button" class="adv-modal__close-btn" data-close="true"></button>
  <h2 class="adv-modal__form--title">
    Dodaj ogłoszenie
  </h2>
  <form class="adv-modal__form" name="advForm">
    <div class="main-adv-div">
      <div class="input-wrapper">
        <p class="adv-modal__product-name-title">Nazwa towaru</p>
        <input type="text" name="productName" class="adv-modal__product-name input" data-source="name"
          autocomplete="off" minlength="6" maxlength="60" placeholder="Nazwa towaru" required>
      </div>
      <div class="test input-wrapper">
        <p class="adv-modal__product-photos-title">Dodaj zdjęcie</p>
        <ul class="adv-modal__product-photos">
          <li class="adv-modal__product-photos-item">
            <input class="photo-input" data-id="1" data-active="true" id="fp1">
            <label class="input-label choose-this" for="fp1"><img src="" class="input-label__img input-label__img--1"
                width="75" height="58" alt=""></label>
          </li>
          <li class="adv-modal__product-photos-item">
            <input class="photo-input" data-id="2" data-active="" id="fp2">
            <label class="input-label" for="fp2"><img src="" class="input-label__img input-label__img--2" width="75"
                height="58" alt=""></label>
          </li>
          <li class="adv-modal__product-photos-item">
            <input class="photo-input" data-id="3" data-active="" id="fp3">
            <label class="input-label" for="fp3"><img src="" class="input-label__img input-label__img--3" width="75"
                height="58" alt=""></label>
          </li>
          <li class="adv-modal__product-photos-item">
            <input class="photo-input" data-id="4" data-active="" id="fp4">
            <label class="input-label" for="fp4"><img src="" class="input-label__img input-label__img--4" width="75"
                height="58" alt=""></label>
          </li>
          <li class="adv-modal__product-photos-item">
            <input class="photo-input" data-id="5" data-active="" id="fp5">
            <label class="input-label" for="fp5"><img src="" class="input-label__img input-label__img--5" width="75"
                height="58" alt=""></label>
          </li>
          <li class="adv-modal__product-photos-item">
            <input class="photo-input" data-id="6" data-active="" id="fp6">
            <label class="input-label" for="fp6"><img src="" class="input-label__img input-label__img--6" width="75"
                height="58" alt=""></label>
          </li>
        </ul>
      </div>
      <div class="input-wrapper">
        <p class="adv-modal__product-description-title">Opis</p>
        <textarea name="productDescription" data-source="description" class="adv-modal__product-description input"
          maxlength="1000" placeholder="Opis"></textarea>
      </div>
      <div class="input-wrapper">
        <p class="adv-modal__product-select-title">Kategoria</p>
        <div class="select">
          <select name="productCategory" class="adv-modal__product-select input" id="">
            <option value="property" class="select-option">Nieruchomości</option>
            <option value="transport" class="select-option">Motoryzacja</option>
            <option value="work" class="select-option">Praca</option>
            <option value="electronics" class="select-option">Elektronika</option>
            <option value="businessAndServices" class="select-option">Usługi i Firmy</option>
            <option value="recreationAndSport" class="select-option">Sport i Hobby</option>
            <option value="free" class="select-option">Oddam za darmo</option>
            <option value="trade" class="select-option">Zamienię</option>
          </select>
        </div>
      </div>
      <div class="input-wrapper input-wrapper__price">
        <p class="adv-modal__product-price-title">Cena</p>
        <input type="number" name="productPrice" data-source="price" class="adv-modal__product-price input"
          autocomplete="off" maxlength="16" placeholder="0.00 pln">
      </div>
      <p class="error hide img-error">Pokaż zdjęcie</p>
      <p class="error hide description-error">Pokaż opis</p>
      <p class="error hide price-error">Pokaż cenę</p>
      <p class="error hide title-error">Pokaż nazwę</p>
      <p class="error hide price-error-2">Pokaż cenę</p>
    </div>
    <button type="submit" class="add-product-btn" data-close="">Dodaj</button>
  </form>
</div>