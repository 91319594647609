<div class="account-wrapper">
  <span class="modal__icon--close close__my-account"></span>

  <div class="user-avatar fileForm">
    <input class="user-avatar__file-input" type="file" name="file" id="file-reader">

    <div class="js-avatar">
      <img class="avatar avatar--hover avatare-large" src="{{avatar}}" alt="Użytkownik: {{firstName}} {{secondName}}"
        width="85" height="85">
    </div>

    <label class="user-avatar__input-label" for="file-reader">
      <span class="user-avatar__add-btn avatar__icon--camera__add">
        <span class="visually-hidden">Załaduj zdjęcie</span>
      </span>
    </label>

    <label class="user-avatar__input-label">
      <span class="user-avatar__clear-btn avatar__icon--camera__clear">
        <span class="visually-hidden">Usuń zdjęcie</span>
      </span>
    </label>
  </div>

  <ul class="user-info">
    <li class="user-info__fullname">{{firstName}} {{secondName}}</li>
    <li class="user-info__mail">{{email}}</li>
    <li class="user-info__phone">{{phone}}</li>
  </ul>

  <ul class="account-list hr--line">
    <li class="account-list__favorites icon--heart">
      <span class="account-list__favorites-link">Obserwowane</span>
      <span class="js-counter__heart counter">0</span>
    </li>

    <li class="account-list__advertisement icon--add">
      <span class="account-list__advertisement-link">Ogłoszenia</span>
      <span class="js-counter__add counter">0</span>
    </li>
  </ul>

  <button class="auth-form-sign-out account-logout icon--logout" data-btn="signout">Wyloguj się ze swojego
    konta</button>
</div>