<li class="categories-filter__item">
    <button data-category="property" class="categories-filter__item--btn">Nieruchomości</button>
</li>
<li class="categories-filter__item">
    <button data-category="transport" class="categories-filter__item--btn">Motoryzacja</button>
</li>
<li class="categories-filter__item">
    <button data-category="work" class="categories-filter__item--btn">Praca</button>
</li>
<li class="categories-filter__item">
    <button data-category="electronics" class="categories-filter__item--btn">Elektronika</button>
</li>
<li class="categories-filter__item">
    <button data-category="businessAndServices" class="categories-filter__item--btn">
        Usługi i Firmy
    </button>
</li>
<li class="categories-filter__item">
    <button data-category="recreationAndSport" class="categories-filter__item--btn">
        Sport i Hobby
    </button>
</li>
<li class="categories-filter__item">
    <button data-category="free" class="categories-filter__item--btn">
        Oddam za darmo
    </button>
</li>
<li class="categories-filter__item">
    <button data-category="trade" class="categories-filter__item--btn">Zamienię</button>
</li>